<template>
  <div>
    <EditDialog
      width="60%"
      :isReturn="true"
      @closeFn="$emit('update:printDiaLog', false)"
      ref="IframeDlg"
      :isShow.sync="printDiaLog"
    >
      <div slot="content" class="content">
        <div id="printArea">
          <template v-for="(n, i) in innerArr">
            <div :key="i">
              <div class="outer-table">{{ newDate | completeTime }}</div>
              <table border="1">
                <thead>
                  <tr>
                    <th colspan="6" style="font-size: 20px; text-align: center">预支归还申请单</th>
                  </tr>
                  <tr>
                    <th>申请单位</th>
                    <th colspan="5">{{ form.companyType | dict(companyType) }}</th>
                  </tr>
                  <tr>
                    <th>申请人</th>
                    <th>{{ form.createName }}</th>
                    <th>部门</th>
                    <th>{{ form.deptName }}</th>
                    <th>申请日期</th>
                    <th>{{ form.taskDate | dateFormat }}</th>
                  </tr>
                  <tr>
                    <th>归还日期</th>
                    <th>{{ form.counteractDate | dateFormat }}</th>
                    <th>归还金额</th>
                    <th>{{ form.cost | applyAmount }}</th>
                    <th>金额大写</th>
                    <th>{{ form.cost | numberParseChina }}</th>
                  </tr>
                  <tr>
                    <th rowspan="2">归还说明</th>
                    <th rowspan="2" colspan="5">{{ form.remark }}</th>
                  </tr>
                  <tr></tr>
                  <tr>
                    <th>序号</th>
                    <th>单据编号</th>
                    <th>申请日期</th>
                    <th>预支总金额</th>
                    <th>未冲销/归还总金额</th>
                    <th>归还金额</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    class="detail"
                    v-for="(item, index) in n.counteractExpenseList"
                    :key="index + i"
                  >
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>{{ item.expenseNumber }}</td>
                    <td>{{ item.taskDate | dateFormat }}</td>
                    <td>{{ item.cost | applyAmount }}</td>
                    <td>{{ item.sumNotCounteractCost | applyAmount }}</td>
                    <td>{{ item.counteractCost | applyAmount }}</td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr style="height: 1px; border: 0px">
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                  </tr>
                  <tr>
                    <td>会计核查</td>
                    <td colspan="2">{{ form.auditUserName }}</td>
                    <td>发起人</td>
                    <td colspan="2">{{ form.createName }}</td>
                  </tr>
                </tfoot>
              </table>
              <div class="outer-table">{{ i + 1 }}/{{ innerArr.length }}</div>
            </div>
          </template>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" v-print="print">打印</el-button>
        <el-button type="info" @click="$emit('update:printDiaLog', false)">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    form: {
      type: Object,
      default: function () {
        return {}
      },
    },
    printDiaLog: {
      type: Boolean,
      default: false,
    },
    companyType: {
      type: Array,
      default: function () {
        return []
      },
    },
    innerArr: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      print: {
        id: 'printArea',
        popTitle: '', // 打印配置页上方的标题
        type: 'html', //打印类型是html
        scanStyles: false,
        targetStyles: ['*'],
      },
      newDate: Date.now(),
    }
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.outer-table {
  // display: none;
  text-align: right;
  font-size: 16px !important;
}
#printArea {
  font-family: '微软雅黑';
  width: 1000px;
}
#printArea table {
  width: 100%;
  word-break: break-all !important;
  font-size: 12px !important;
  text-align: center;
  tr {
    height: 48px;
  }
}
@media print {
  @page {
    margin: 8mm 10mm;
    size: landscape; //横向
  }

  tbody {
    page-break-inside: avoid; /* 避免将tbody分割在多个页面上 */
  }
  .outer-table {
    display: block;
  }
}
</style>
